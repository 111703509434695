import React, { useState, useEffect, useMemo, useRef, MouseEvent } from 'react'
import * as yup from 'yup'
import { Col, Row } from 'react-grid-system'
import classNames from 'classnames'
import { BiTargetLock as TargetIcon } from 'react-icons/bi'
import { BsCloudArrowUp as ImportIcon } from 'react-icons/bs'

import { TableWrapper } from '../../common/table/TableWrapper'
import {
   PageHeader,
   Search,
   Modal,
   TrueOrFalse,
   Button,
   CrudActionButtons,
   LoadingOverlay,
   SnackBar,
   SlidePanel,
   Filters,
   Svg,
} from '../../common'
import { Input, Toggle } from '../../common/form/fields'
import FilterButton from '../../common/filters/filterButton/filterButton'
import FilterTags from '../../common/filters/filterTags/filterTags'
import { Actions, PAGINATION } from '../../../constants/tables'
import { Severity } from '../../../types'
import { CRUD_TABLE } from './constants'
import { ToggleFilter } from '../../common/filters/filterTypes'
import { isEmptyObject } from '../../../common/utils/functions'
import {
   useCrudItems,
   useFilters,
   useForm,
   useGeocoder,
   useLocations,
} from '../../../hooks'

import * as styles from '../../views/history/itemHistory/itemHistory.module.scss'
import * as locationStyles from './locations.module.scss'
import * as tableStyles from '../../common/table/table.module.scss'

import { Filters as FiltersObject } from '../../../hooks/useFilters/useFilters.type'
import { formatFiltersForAPI } from '../history/itemHistory/itemHistory'
import { ImportModal } from '../../reusableModals/importModal'

const {
   extraClassName,
   modalTitle,
   placeholder,
   addButtonText,
   key,
   crudPaths,
   tableTitle,
} = CRUD_TABLE

const filterSchema = yup.object().shape({})

const locationSchema = yup.object().shape({
   Description: yup.string().required('This field is required.'),
   Address: yup.string(),
   City: yup.string(),
   State: yup.string(),
   ZipCode: yup.string(),
   PhoneNumber: yup.string(),
   Latitude: yup.string(),
   Longitude: yup.string(),
})

const defaultLocation = {
   Id: '',
   Description: '',
   Address: '',
   City: '',
   State: '',
   ZipCode: '',
   PhoneNumber: '',
   Latitude: '',
   Longitude: '',
   Warehouse: false,
   Active: true,
   Default: false,
   Archived: false,
}

export const Locations = () => {
   const { query, skip, take } = PAGINATION
   const {
      data: crudData,
      get,
      update,
      add,
      remove,
      totalItems,
      setPath,
      isLoading,
      isRefetching,
      isCreating,
      isSuccessCreate,
      isErrorCreate,
      isUpdating,
      isSuccessUpdate,
      isErrorUpdate,
      isRemoving,
      isSuccessRemove,
      isErrorRemove,
      error,
      resetErrorMsg,
   } = useCrudItems({ query, skip, take })

   const [selectedLocation, setSelectedLocation] = useState(defaultLocation)
   const [latLng, setLatLng] = useState<{
      lat: string | number
      lng: string | number
   }>({ lat: '', lng: '' })
   const [toggles, setToggles] = useState<{ [key: string]: boolean }>({})
   const { geocode, loading: geocodeLoading } = useGeocoder()

   const resetAndCloseModal = () => {
      setIsImportModalVisible(false)
      setSelectedLocation(defaultLocation)
      setIsAddEditModalVisible(false)
      setIsDeleteModalVisible(false)
      setToggles({})
   }

   const { onSubmit, validationErrors, onReset } = useForm(
      locationSchema,
      (values) => {
         if (values?.Id) {
            update(values)
         } else {
            add(values)
         }
         resetAndCloseModal()
      }
   )

   const {
      modify,
      reset,
      remove: removeFilter,
      save,
      savedFilters,
      tmpFilters,
   } = useFilters(filterSchema, {})

   const {
      error: importError,
      isImporting,
      isImportSuccess,
      importLocations,
   } = useLocations()

   const [searchMode, setSearchMode] = useState<boolean>(false)
   const [pageSized, setPageSized] = useState<number>(PAGINATION.pageSized)
   const [isPageReset] = useState<boolean>(false)
   const [isAddEditModalVisible, setIsAddEditModalVisible] =
      useState<boolean>(false)
   const [isDeleteModalVisible, setIsDeleteModalVisible] =
      useState<boolean>(false)
   const [isImportModalVisible, setIsImportModalVisible] =
      useState<boolean>(false)
   const [searchCriteria, setSearchCriteria] = useState<string>('')
   const formRef = useRef<HTMLFormElement>()
   const zipCodeRef = useRef<HTMLInputElement>()
   const stateRef = useRef<HTMLInputElement>()
   const cityRef = useRef<HTMLInputElement>()
   const addressRef = useRef<HTMLInputElement>()
   const [messageState, setMessageState] = useState(null)
   const [filtersOpen, setFiltersOpen] = useState<boolean>(false)

   const applySavedFilters = (filters: FiltersObject) => {
      setFiltersOpen(false)
      handleGetItems(searchCriteria, 0, pageSized, filters)
   }

   const handleGetItems = (
      query?: string,
      skip?: number,
      take?: number,
      filters?: {}
   ) => {
      const formattedFilters = formatFiltersForAPI(filters || savedFilters)

      get(query, skip, take, formattedFilters)
   }

   const handleSearch = (
      query?: string,
      skip?: number,
      take?: number,
      filters?: {}
   ) => {
      const formattedFilters = formatFiltersForAPI(filters || savedFilters)

      get(query, skip, take, formattedFilters)
   }

   const handleClose = () => {
      onReset()
      resetAndCloseModal()
   }

   const showActionForm = (action, id?: string) => {
      const locationToEdit = crudData.find((location) => location?.Id === id)
      setSelectedLocation(locationToEdit)

      if (action === Actions.Delete) {
         setIsDeleteModalVisible(true)
      } else {
         setIsAddEditModalVisible(true)
      }
   }

   const handleOpenImportModal = () => setIsImportModalVisible(true)

   const handleToggleChange = (name: string, label: string, value: boolean) => {
      modify(name, label, value)
   }

   const handleRemoveTag = (name: string, id: string): void => {
      const newFilters = removeFilter(name, id)
      applySavedFilters(newFilters)
   }

   const handleFilterSave = async () => {
      const isSaved = await save()

      if (isSaved) {
         applySavedFilters(tmpFilters)
      }
   }

   const handleResetFilters = () => {
      formRef.current.reset()
      reset()
      applySavedFilters({})
   }

   const handleLatLongLookup = async (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      const locationLookup = `${addressRef.current.value}, ${cityRef.current.value}, ${stateRef.current.value}, ${zipCodeRef.current.value}`
      const { lat, lng } = await geocode(locationLookup)
      setSelectedLocation({
         ...selectedLocation,
         Latitude: lat,
         Longitude: lng,
      })
   }

   const handleToggle = (id: string, checked: boolean) => {
      // if warehouse is unchecked then uncheck default warehouse
      const defaultWarehouse =
         id === 'Default'
            ? checked
            : id === 'Warehouse' && checked === false
            ? false
            : toggles.Default
      setToggles((prevState) => {
         return {
            ...prevState,
            [id]: checked,
            Default: defaultWarehouse,
         }
      })
   }

   const handleDeleteLocation = () => {
      remove(selectedLocation?.Id)
      resetAndCloseModal()
   }

   const handleResetMessageState = () => {
      resetErrorMsg()
      setMessageState(null)
   }

   const columns = useMemo(
      () => [
         {
            Header: 'Actions',
            accessor: 'Id',
            minWidth: 80,
            align: 'center',
            Cell: (cell) => (
               <>
                  <CrudActionButtons
                     cell={cell}
                     action={showActionForm}
                     editAction
                     deleteAction
                  />
               </>
            ),
         },
         {
            Header: 'Description',
            accessor: 'Description',
            Cell: (cell: { row: { original: { Description: string } } }) => (
               <div className={tableStyles.tableColumnCellWrapper}>
                  {cell.row.original.Description}
               </div>
            ),
            width: 200,
            minWidth: 20,
         },
         {
            Header: 'Warehouse',
            accessor: 'Warehouse',
            align: 'center',
            width: 100,
            minWidth: 20,
            Cell: ({ row }) => <TrueOrFalse status={row.original.Warehouse} />,
         },
         {
            Header: 'Address',
            accessor: 'Address',
            Cell: (cell: { row: { original: { Address: string } } }) => (
               <div className={tableStyles.tableColumnCellWrapper}>
                  {cell.row.original.Address}
               </div>
            ),
            width: 300,
            minWidth: 20,
         },
         {
            Header: 'City',
            accessor: 'City',
            Cell: (cell: { row: { original: { City: string } } }) => (
               <div className={tableStyles.tableColumnCellWrapper}>
                  {cell.row.original.City}
               </div>
            ),
            width: 150,
            minWidth: 20,
         },
         {
            Header: 'State/Province',
            accessor: 'State',
            Cell: (cell: { row: { original: { State: string } } }) => (
               <div className={tableStyles.tableColumnCellWrapper}>
                  {cell.row.original.State}
               </div>
            ),
            width: 150,
            minWidth: 20,
         },
         {
            Header: 'Postal Code',
            accessor: 'ZipCode',
            width: 100,
            minWidth: 20,
         },
         {
            Header: 'Phone Number',
            accessor: 'PhoneNumber',
            width: 130,
            minWidth: 20,
         },
         {
            Header: 'Latitude',
            accessor: 'Latitude',
            width: 100,
            minWidth: 20,
            align: 'center',
            Cell: ({ row }) => row.original.Latitude || '-',
         },
         {
            Header: 'Longitude',
            accessor: 'Longitude',
            width: 100,
            minWidth: 20,
            align: 'center',
            Cell: ({ row }) => row.original.Longitude || '-',
         },
         {
            Header: 'Active',
            accessor: 'Active',
            width: 100,
            minWidth: 20,
            align: 'center',
            Cell: ({ row }) => <TrueOrFalse status={row.original.Active} />,
         },
         {
            Header: 'Archived',
            accessor: 'Archived',
            width: 100,
            minWidth: 20,
            align: 'center',
            Cell: ({ row }) => <TrueOrFalse status={row.original.Archived} />,
         },
         {
            Header: 'Default',
            accessor: 'Default',
            width: 100,
            minWidth: 20,
            align: 'center',
            Cell: ({ row }) => <TrueOrFalse status={row.original.Default} />,
         },
      ],
      [crudData]
   )

   useEffect(() => {
      setPath(crudPaths, key)
   }, [])

   function handleMessage(message, severity) {
      setMessageState({ message, severity })
   }

   useEffect(() => {
      setSelectedLocation({ ...selectedLocation, ...toggles })
   }, [toggles])

   // handle actions
   useEffect(() => {
      handleMessage('Location has been updated.', Severity.SUCCESS)
   }, [isSuccessUpdate])

   useEffect(() => {
      handleMessage('Location has been created.', Severity.SUCCESS)
   }, [isSuccessCreate])

   useEffect(() => {
      handleMessage('Location has been removed.', Severity.SUCCESS)
   }, [isSuccessRemove])

   // handle errors
   useEffect(() => {
      if (isErrorCreate) {
         handleMessage(
            'Creating a location has been unsuccessful.',
            Severity.ERROR
         )
      }
   }, [isErrorCreate])

   useEffect(() => {
      if (isErrorUpdate) {
         handleMessage(
            'Updating a location has been unsuccessful.',
            Severity.ERROR
         )
      }
   }, [isErrorUpdate])

   useEffect(() => {
      if (isErrorRemove) {
         handleMessage(error, Severity.ERROR)
      }
   }, [isErrorRemove, error])

   const handleSuccessfulImport = () => {
      handleMessage('Locations imported successfully.', Severity.SUCCESS)
      handleGetItems(query, 0, take)
      resetAndCloseModal()
   }

   useEffect(() => {
      if (!isEmptyObject(selectedLocation)) {
         setLatLng({
            lat: selectedLocation?.Latitude,
            lng: selectedLocation?.Longitude,
         })
      }
   }, [selectedLocation])

   const showSnackbar =
      isSuccessUpdate ||
      isSuccessCreate ||
      isSuccessRemove ||
      isErrorCreate ||
      isErrorUpdate ||
      isErrorRemove ||
      isImportSuccess ||
      importError?.length > 0

   const showLoadingSpinner =
      isLoading || isUpdating || isCreating || isRemoving || isRefetching

   return (
      <div>
         {showLoadingSpinner && <LoadingOverlay />}
         <SnackBar
            message={messageState?.message}
            open={showSnackbar}
            severity={messageState?.severity}
            disableAutoClose={messageState?.disableAutoClose}
            resetMessageStateHandler={handleResetMessageState}
         />

         <PageHeader title={tableTitle}>
            <Button
               onClick={() => showActionForm(Actions.Add, '')}
               preserveText
               variant="plain"
               minWidth="0"
            >
               <Svg id="plus" /> {addButtonText}
            </Button>
            <Button
               onClick={handleOpenImportModal}
               preserveText
               variant="plain"
               minWidth="0"
            >
               <ImportIcon /> Import Locations
            </Button>
         </PageHeader>

         <div className={styles.searchWrapper}>
            <div className={styles.searchWrapperSearch}>
               <Search
                  handleQuery={handleSearch}
                  searchMode={searchMode}
                  setSearchMode={setSearchMode}
                  pageSized={pageSized}
                  placeHolder={placeholder}
                  setSearchCriteria={setSearchCriteria}
               />
            </div>
            <div className={styles.searchWrapperFilter}>
               <FilterButton
                  isActive={!isEmptyObject(savedFilters)}
                  onClick={() => setFiltersOpen(!filtersOpen)}
               />
            </div>
         </div>

         <FilterTags filters={savedFilters} onClick={handleRemoveTag} />

         <TableWrapper
            isLoading={isLoading}
            data={crudData}
            columns={columns}
            totalCount={totalItems}
            getItems={handleGetItems}
            takeItems={PAGINATION.take}
            skipItems={PAGINATION.skip}
            setSearchMode={setSearchMode}
            searchMode={searchMode}
            query={query}
            isPageReset={isPageReset}
            setPageSized={setPageSized}
            extraClassName={extraClassName}
            searchCriteria={searchCriteria}
         />

         <SlidePanel isOpen={filtersOpen} onClose={() => setFiltersOpen(false)}>
            <Filters onReset={handleResetFilters} onSave={handleFilterSave}>
               <form ref={formRef}>
                  <ToggleFilter
                     defaultValue={tmpFilters?.hideInactive?.value}
                     id="hideInactive"
                     onChange={handleToggleChange}
                     title="Hide Inactive"
                  />

                  <ToggleFilter
                     defaultValue={tmpFilters?.includeArchived?.value}
                     id="includeArchived"
                     onChange={handleToggleChange}
                     title="Include Archived"
                  />
               </form>
            </Filters>
         </SlidePanel>

         <ImportModal
            variant="Locations"
            isModalOpen={isImportModalVisible}
            handleCloseImportModal={handleClose}
            handleSuccessfulImport={handleSuccessfulImport}
            importSelectedFile={importLocations}
            isImportingData={isImporting}
            importError={importError}
            importFileErrors={[]}
            isImportingSuccess={isImportSuccess}
         />

         <Modal
            isModalVisible={isDeleteModalVisible}
            closeModal={handleClose}
            title={`Delete${modalTitle}`}
         >
            <div className={locationStyles.deleteForm}>
               <p>
                  Are you sure you wish to delete{' '}
                  <strong>{selectedLocation?.Description}</strong>?
               </p>

               <Row gutterWidth={20}>
                  <Col xs={6}>
                     <Button
                        minWidth="100%"
                        variant="danger"
                        onClick={handleDeleteLocation}
                     >
                        Delete
                     </Button>
                  </Col>
                  <Col xs={6}>
                     <Button
                        minWidth="100%"
                        variant="tertiary"
                        onClick={handleClose}
                        isReset
                     >
                        Cancel
                     </Button>
                  </Col>
               </Row>
            </div>
         </Modal>

         <Modal
            isModalVisible={isAddEditModalVisible}
            closeModal={handleClose}
            title={`${!!selectedLocation?.Id ? 'Edit' : 'Add'}${modalTitle}`}
         >
            {isAddEditModalVisible && (
               <form
                  className={locationStyles.form}
                  noValidate
                  onSubmit={onSubmit}
               >
                  <input
                     type="hidden"
                     name="Warehouse"
                     value={selectedLocation?.Warehouse?.toString() || 'false'}
                  />
                  <input
                     type="hidden"
                     name="Active"
                     value={selectedLocation?.Active?.toString() || 'true'}
                  />
                  <input
                     type="hidden"
                     name="Archived"
                     value={selectedLocation?.Archived?.toString() || 'false'}
                  />
                  <input
                     type="hidden"
                     name="Default"
                     value={selectedLocation?.Default?.toString() || 'false'}
                  />
                  <input type="hidden" name="Id" value={selectedLocation?.Id} />

                  <Input
                     error={validationErrors?.Description}
                     id="Description"
                     label="Description"
                     required
                     value={selectedLocation?.Description}
                  />

                  <Row gutterWidth={20}>
                     <Col xs={12} md={6}>
                        <Input
                           error={validationErrors?.Address}
                           id="Address"
                           label="Address"
                           value={selectedLocation?.Address}
                           ref={addressRef}
                        />
                        <Input
                           error={validationErrors?.City}
                           id="City"
                           label="City"
                           value={selectedLocation?.City}
                           ref={cityRef}
                        />
                        <Input
                           error={validationErrors?.State}
                           id="State"
                           label="State/Province"
                           value={selectedLocation?.State}
                           ref={stateRef}
                        />
                        <Input
                           error={validationErrors?.ZipCode}
                           id="ZipCode"
                           label="Postal Code"
                           value={selectedLocation?.ZipCode}
                           ref={zipCodeRef}
                        />
                        <Input
                           error={validationErrors?.PhoneNumber}
                           id="PhoneNumber"
                           label="Phone Number"
                           value={selectedLocation?.PhoneNumber}
                           noMargin
                        />
                     </Col>
                     <Col xs={12} md={6}>
                        <p className={classNames(locationStyles.description)}>
                           If an address is entered, click here to calculate the
                           latitude and longitude values so that items can be
                           shown on Google Maps.
                        </p>
                        <Button
                           className={locationStyles.coordinatesButton}
                           variant="secondary"
                           minWidth="100%"
                           onClick={handleLatLongLookup}
                           loading={geocodeLoading}
                        >
                           <span className={locationStyles.icon}>
                              <TargetIcon />
                           </span>
                           <span>Look up</span>
                        </Button>
                        <Input
                           error={validationErrors?.Latitude}
                           id="Latitude"
                           label="Latitude"
                           value={latLng.lat}
                        />
                        <Input
                           error={validationErrors?.Longitude}
                           id="Longitude"
                           label="Longitude"
                           value={latLng.lng}
                        />
                     </Col>
                  </Row>

                  <hr />

                  <Row>
                     <Col xs={12} md={12} lg={2}>
                        <div className={locationStyles.toggle}>
                           <span className={locationStyles.toggleField}>
                              <Toggle
                                 id="Warehouse"
                                 size="md"
                                 isChecked={selectedLocation?.Warehouse}
                                 onToggle={handleToggle}
                              />
                           </span>
                           <span className={locationStyles.toggleLabel}>
                              Warehouse
                           </span>
                        </div>
                     </Col>
                     <Col xs={12} md={12} lg={4}>
                        <p
                           className={classNames(
                              locationStyles.description,
                              locationStyles.warehouseDescription
                           )}
                        >
                           Enable this option to designate this location as a
                           warehouse. Only warehouses can be designated as a
                           return location. &nbsp;
                        </p>
                     </Col>

                     <Col xs={12} md={12} lg={2}>
                        <div className={locationStyles.toggle}>
                           <span className={locationStyles.toggleField}>
                              <Toggle
                                 id="Default"
                                 size="md"
                                 isChecked={selectedLocation?.Default}
                                 onToggle={handleToggle}
                                 disabled={!selectedLocation?.Warehouse}
                              />
                           </span>
                           <span className={locationStyles.toggleLabel}>
                              Default
                           </span>
                        </div>
                     </Col>
                     <Col xs={12} md={12} lg={4}>
                        <p
                           className={classNames(
                              locationStyles.description,
                              locationStyles.warehouseDescription
                           )}
                        >
                           Enable this option to designate a default warehouse.
                           If no return location is entered on the item record
                           this will be used. This is only used for locations
                           designated as warehouses.
                        </p>
                     </Col>
                  </Row>

                  <hr />

                  {selectedLocation?.Id && (
                     <>
                        <Row>
                           <Col xs={12} md={12} lg={2}>
                              <div className={locationStyles.toggle}>
                                 <span className={locationStyles.toggleField}>
                                    <Toggle
                                       id="Active"
                                       size="md"
                                       isChecked={selectedLocation?.Active}
                                       onToggle={handleToggle}
                                    />
                                 </span>
                                 <span className={locationStyles.toggleLabel}>
                                    Active
                                 </span>
                              </div>
                           </Col>

                           <Col xs={12} md={12} lg={4}>
                              <p
                                 className={classNames(
                                    locationStyles.description,
                                    locationStyles.warehouseDescription
                                 )}
                              >
                                 By default, a location is active. This allows
                                 you to filter locations on the location grid
                                 based on status.
                              </p>
                           </Col>

                           <Col xs={12} md={12} lg={2}>
                              <div className={locationStyles.toggle}>
                                 <span className={locationStyles.toggleField}>
                                    <Toggle
                                       id="Archived"
                                       size="md"
                                       isChecked={selectedLocation?.Archived}
                                       onToggle={handleToggle}
                                    />
                                 </span>
                                 <span className={locationStyles.toggleLabel}>
                                    Archived
                                 </span>
                              </div>
                           </Col>

                           <Col xs={12} md={12} lg={4}>
                              <p
                                 className={classNames(
                                    locationStyles.description,
                                    locationStyles.warehouseDescription
                                 )}
                              >
                                 Enable this option to designate a location as
                                 archived. This allows you to filter locations
                                 based upon this status on the location grid.
                              </p>
                           </Col>
                        </Row>

                        <hr />
                     </>
                  )}

                  <Row gutterWidth={20}>
                     <Col xs={6}>
                        <Button minWidth="100%">Save</Button>
                     </Col>
                     <Col xs={6}>
                        <Button
                           minWidth="100%"
                           variant="tertiary"
                           onClick={handleClose}
                           isReset
                        >
                           Cancel
                        </Button>
                     </Col>
                  </Row>
               </form>
            )}
         </Modal>
      </div>
   )
}
